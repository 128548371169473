import * as React from 'react';
import moment from 'moment';
//

const Edeka = () => {
  React.useEffect(() => {
    const weekNum = moment().isoWeek();
    console.log(`weekNum: ${weekNum}`);
    const _link = `/edeka-bauer/week-${weekNum}/`
    // const link = withPrefix(_link)
    console.log('navigate to: ', _link)
    window.location.replace(_link)
  })

  return (<></>);
}
export default Edeka;